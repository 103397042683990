import { api } from "./index"
import { BaseResponseType, IProfile, ISettings, IUser } from "../../types/content"
// import { BaseResponseType } from '@/types/content'
// import { LoginRequest, UserData } from '@/types/auth'

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSms: builder.mutation<BaseResponseType<{ ttl: number }>, { phoneNumber: string }>({
      query: (data) => ({
        url: "public/login/get-sms",
        method: "POST",
        body: { ...data },
      }),
    }),
    loginSms: builder.mutation<
      BaseResponseType<{ user: IUser; attemptsLeft?: any; ttl?: number }>,
      { phoneNumber: string; code: string }
    >({
      query: (data) => ({
        url: "public/login/sms",
        method: "POST",
        body: { ...data },
      }),
    }),
    logoutApi: builder.mutation<any, string | null | boolean | void>({
      query: () => ({
        url: "public/logout",
        method: "POST",
      }),
    }),
    checkToken: builder.mutation<BaseResponseType<{ user: IUser }>, string | null | boolean | void>({
      query: () => ({
        url: "public/login/check",
        method: "GET",
      }),
    }),
    getKerbToken: builder.mutation<any, void>({
      query: () => ({
        url: "public/login/kerb",
        method: "GET",
      }),
    }),
    getCompanyLogo: builder.query<any, void>({
      query: () => ({
        url: `public/photo/company-logo`,
        responseHandler: "content-type",
      }),
    }),
    getSettings: builder.query<ISettings, void>({
      query: () => ({
        url: "public/settings",
        method: "GET",
      }),
    }),
    setNewUserName: builder.mutation<any, { name: string }>({
      query: (data) => ({
        url: "public/user/set-name",
        method: "PUT",
        body: { ...data },
      }),
    }),
    getCodeByEmail: builder.mutation<BaseResponseType<{ ttl: number }>, { email: string }>({
      query: (data) => ({
        url: "public/login/get-email",
        method: "POST",
        body: { ...data },
      }),
    }),
    loginEmail: builder.mutation<
      BaseResponseType<{ user: IUser; attemptsLeft?: any; ttl?: number }>,
      { email: string; code: string }
    >({
      query: (data) => ({
        url: "public/login/email",
        method: "POST",
        body: { ...data },
      }),
    }),
    getProfile: builder.query<BaseResponseType<IProfile>, void>({
      query: () => ({
        url: "public/profile",
        method: "GET",
      }),
    }),
    setProfileAvatar: builder.mutation<BaseResponseType<any>, { avatar: string | null }>({
      query: (data) => ({
        url: "public/profile/set-avatar",
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSmsMutation,
  useLoginSmsMutation,
  useCheckTokenMutation,
  useGetCompanyLogoQuery,
  useGetSettingsQuery,
  useSetNewUserNameMutation,
  useGetKerbTokenMutation,
  useLogoutApiMutation,
  useGetCodeByEmailMutation,
  useLoginEmailMutation,
  useSetProfileAvatarMutation,
  useLazyGetProfileQuery,
} = authApi
