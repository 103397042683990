// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesMaybe_block__Xranr {
  box-shadow: none;
}
@media (min-width: 1200px) {
  .ArticlesMaybe_block__Xranr {
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  }
}
.ArticlesMaybe_block__Xranr .paper__main {
  padding: 0;
}
@media (min-width: 1200px) {
  .ArticlesMaybe_block__Xranr .paper__main {
    padding: 15px 12px 12px;
  }
}

.ArticlesMaybe_title__E5i5v {
  margin-bottom: 12px;
}
@media (min-width: 1200px) {
  .ArticlesMaybe_title__E5i5v {
    margin-bottom: 27px;
  }
}

.ArticlesMaybe_list__IlObm {
  list-style-type: none;
  padding-left: 8px;
}
@media (min-width: 1200px) {
  .ArticlesMaybe_list__IlObm {
    padding: 0;
  }
}

.ArticlesMaybe_li__CYryp {
  margin-bottom: 24px;
}
.ArticlesMaybe_li__CYryp:last-child {
  margin-bottom: 0;
}

.ArticlesMaybe_loader__0EvN5 {
  height: 240px;
  border-radius: 12px;
}

.ArticlesMaybe_articleWrap__clamF {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/ArticlesMaybe/ArticlesMaybe.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EAHF;IAII,uDAAA;EAEF;AACF;AAEM;EACE,UAAA;AAAR;AAEQ;EAHF;IAII,uBAAA;EACR;AACF;;AAKA;EACE,mBAAA;AAFF;AAIE;EAHF;IAII,mBAAA;EADF;AACF;;AAIA;EACE,qBAAA;EACA,iBAAA;AADF;AAGE;EAJF;IAKI,UAAA;EAAF;AACF;;AAGA;EACE,mBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,mBAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":[".block {\r\n  box-shadow: none;\r\n\r\n  @media (min-width: 1200px) {\r\n    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);\r\n  }\r\n\r\n  :global {\r\n    .paper {\r\n      &__main {\r\n        padding: 0;\r\n\r\n        @media (min-width: 1200px) {\r\n          padding: 15px 12px 12px;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.title {\r\n  margin-bottom: 12px;\r\n\r\n  @media (min-width: 1200px) {\r\n    margin-bottom: 27px;\r\n  }\r\n}\r\n\r\n.list {\r\n  list-style-type: none;\r\n  padding-left: 8px;\r\n\r\n  @media (min-width: 1200px) {\r\n    padding: 0;\r\n  }\r\n}\r\n\r\n.li {\r\n  margin-bottom: 24px;\r\n\r\n  &:last-child {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\n.loader {\r\n  height: 240px;\r\n  border-radius: 12px;\r\n}\r\n\r\n.articleWrap {\r\n  display: inline-block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `ArticlesMaybe_block__Xranr`,
	"title": `ArticlesMaybe_title__E5i5v`,
	"list": `ArticlesMaybe_list__IlObm`,
	"li": `ArticlesMaybe_li__CYryp`,
	"loader": `ArticlesMaybe_loader__0EvN5`,
	"articleWrap": `ArticlesMaybe_articleWrap__clamF`
};
export default ___CSS_LOADER_EXPORT___;
