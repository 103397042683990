export const EditIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58159 9.57382C3.75707 9.53103 4.06198 9.45674 4.34318 9.29757C4.62438 9.1383 4.84501 8.9151 4.97198 8.78663L5.00736 8.75097L9.07035 4.68798L9.12178 4.63707C9.29467 4.46668 9.58747 4.17813 9.76164 3.82665C10.0795 3.18531 10.0795 2.43233 9.76164 1.79098C9.58746 1.4395 9.29467 1.15096 9.12178 0.98056L9.07035 0.929646L9.01944 0.878215C8.84904 0.705326 8.5605 0.412541 8.20902 0.23837C7.56768 -0.0794548 6.81469 -0.0794545 6.17335 0.238358C5.82187 0.412541 5.53331 0.705326 5.36293 0.878215L5.31202 0.929646L1.24903 4.99264L1.21337 5.02801C1.08491 5.15499 0.861698 5.37562 0.702481 5.65682C0.543274 5.93801 0.46892 6.24293 0.426142 6.41841L0.414151 6.46719L0.123024 7.63169L0.114936 7.66384C0.0839596 7.78639 0.0287554 8.00486 0.00911177 8.20561C-0.0140938 8.44283 -0.0307867 9.04328 0.46296 9.53703C0.956719 10.0308 1.55717 10.0141 1.79439 9.99091C1.99514 9.97128 2.2136 9.91603 2.33616 9.88511L2.36831 9.877L3.53281 9.58581L3.58159 9.57382ZM2.24653 5.99014L5.51667 2.71999L7.28001 4.48333L4.00987 7.75348C3.83825 7.92509 3.75244 8.0109 3.64815 8.06995C3.54386 8.129 3.42612 8.15844 3.19067 8.2173L2.02617 8.50842C1.70787 8.588 1.54871 8.62779 1.46046 8.53954C1.37221 8.45129 1.412 8.29214 1.49158 7.97383L1.7827 6.80933C1.84157 6.57388 1.871 6.45614 1.93005 6.35185C1.9891 6.24756 2.07491 6.16175 2.24653 5.99014ZM8.27627 3.48461C8.38742 3.36858 8.45491 3.28653 8.49764 3.20029C8.61988 2.95362 8.61988 2.66401 8.49764 2.41734C8.4334 2.28769 8.31322 2.16751 8.07285 1.92714C7.83249 1.68678 7.71231 1.5666 7.58266 1.50236C7.33599 1.38012 7.04638 1.38012 6.79971 1.50236C6.71347 1.54509 6.63142 1.61258 6.51539 1.72373L8.27627 3.48461Z"
      />
    </svg>
  )
}
