import Layout from "../components/Layout/Layout"
import EmployeesLayoutLoader from "../components/LayoutLoaders/EmployeesLayoutLoader"
import Profile from "../components/Profile/Profile"

export default function ProfilePage() {
  return (
    <Layout mainLoader={<EmployeesLayoutLoader />} isHeaderMobHidden>
      <Profile />
    </Layout>
  )
}
