import clsx from "clsx"
import Paper from "../Assets/Paper/Paper"
import ArticlePrevItem from "../Articles/ArticlePrevItem/ArticlePrevItem"
import { useGetArticlesByServiceIDQuery } from "../../redux/api/articles"
import styles from "./ArticlesMaybe.module.scss"

const ArticlesMaybe = ({ serviceID, className }: { serviceID: string; className?: string }) => {
  const { data: articles, isLoading } = useGetArticlesByServiceIDQuery(serviceID)

  return isLoading ? (
    <div className={clsx("skeletonBlock", styles.loader)} />
  ) : articles && articles?.length > 0 ? (
    <Paper className={clsx(styles.block, className)}>
      <h4 className={styles.title}>Возможно вам поможет</h4>
      <ul className={styles.list}>
        {articles?.map((article) => {
          return (
            <li key={article.id} className={styles.li}>
              <ArticlePrevItem key={article.id} {...article} isTarget className={styles.articleWrap} />
            </li>
          )
        })}
      </ul>
    </Paper>
  ) : null
}

export default ArticlesMaybe
