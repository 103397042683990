import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import { useLocation, useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"
import { selectModals } from "../../redux/slice/modals"

const OpenChatBySearchParams = () => {
  const user = useAppSelector(selectUser)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { isOrderModalOpen } = useAppSelector(selectModals)
  const firstOpen = useRef(false)

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [currentLayout, setCurrentLayout] = useState<"history" | "active" | "coordination" | undefined>()

  useEffect(() => {
    if (!user?.id || firstOpen.current || isOrderModalOpen || isModalOpen) return
    const orderID = searchParams.get("order_id")
    if (!orderID) return
    const isSomeModalOpened = document?.getElementById("modal")
    if (isSomeModalOpened) return

    if (location.pathname === "/coordinations") {
      setCurrentLayout("coordination")
    } else if (location.pathname === "/history") {
      setCurrentLayout("history")
    } else {
      setCurrentLayout("active")
    }

    setModalOpen(true)
    firstOpen.current = true

    return () => {
      if (!isOrderModalOpen) return
      setModalOpen(false)
    }
  }, [user?.id, searchParams, location, isOrderModalOpen, isModalOpen, firstOpen])

  return (
    <>
      {isModalOpen && searchParams.get("order_id") && (
        <>
          <OrderInfoModal
            chatID={""}
            id={searchParams.get("order_id") || ""}
            open={isModalOpen}
            setOpen={setModalOpen}
            layout={currentLayout}
          />
        </>
      )}
    </>
  )
}

export default OpenChatBySearchParams
