import React from "react"
import styles from "./OrderModal.module.scss"
import clsx from "clsx"

interface Props {
  title?: string | number
  children: any
  isRequired?: boolean
  error?: boolean
  className?: string
}

const OrderModalBlock: React.FC<Props> = ({ title, children, isRequired, error, className }) => {
  return (
    <div className={clsx(styles.orderModal__block, className)}>
      {!!title && (
        <div
          className={`orderModal__blockTitle ${styles.orderModal__blockTitle} ${
            error && isRequired ? `${styles.orderModal__blockRequired} error` : ""
          }`}
        >
          {title} {isRequired && <span className={styles.orderModal__blockRequired}>*</span>}
        </div>
      )}
      {children}
    </div>
  )
}

export default OrderModalBlock
