import React, { useEffect, useState, useRef } from "react"
import styles from "./CropModal.module.scss"
import { Cropper } from "react-cropper"
import { useTranslation } from "react-i18next"
import ModalPortal from "../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import Button from "../Assets/Button/Button"

interface Props {
  modalOpen: boolean
  setModalOpen: any
  submitModal: any
  image: any
  isServices?: boolean
}

const CropModalPopup: React.FC<Props> = ({ modalOpen = false, setModalOpen, submitModal, image }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [rotate, setRotate] = useState(0)
  const [cropper, setCropper] = useState<any>()

  useEffect(() => {
    cropper?.rotateTo(rotate)
  }, [rotate])

  useEffect(() => {
    if (!modalOpen) {
      // inputRef.current.value = ''
      setRotate(0)
    }
  }, [modalOpen])

  return (
    <ModalPortal isOpen={modalOpen} setIsOpen={setModalOpen} className={styles.modal}>
      <div className={`${styles["cropModalPopup"]}`}>
        <div className={`modal__title`}>{t("selectArea")}</div>
        <Cropper
          style={{ height: 350, width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          aspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          dragMode={"move"}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={true}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance: any) => {
            setCropper(instance)
          }}
          guides={false}
          rotatable={true}
          toggleDragModeOnDblclick={false}
        />
        <div className={`${styles["cropModalPopup__button"]}`}>
          <Button
            txt={t("rotate")}
            className={styles["rotate-btn"]}
            onClick={() => {
              setRotate((prev) => (prev !== 360 ? (prev += 90) : 90))
            }}
          />
          <Button
            txt={t("apply")}
            className={styles.btn}
            onClick={() => {
              submitModal(cropper.getCroppedCanvas().toDataURL())
              setModalOpen(false)
            }}
          />
        </div>
      </div>
    </ModalPortal>
  )
}

export default CropModalPopup
