// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextArea_textarea__WGFsF {
  width: 100%;
  min-height: 72px;
  background-color: #F9FAFB;
  resize: none;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  padding: 8px;
}
.TextArea_textarea__WGFsF::placeholder {
  color: rgba(84, 87, 92, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/TextArea/TextArea.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,4BAAA;AACJ","sourcesContent":[".textarea {\r\n  width: 100%;\r\n  min-height: 72px;\r\n  background-color: #F9FAFB;\r\n  resize: none;\r\n  font-size: 12px;\r\n  line-height: 14px;\r\n  border-radius: 8px;\r\n  padding: 8px;\r\n\r\n  &::placeholder {\r\n    color: rgba(84, 87, 92, 0.4);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `TextArea_textarea__WGFsF`
};
export default ___CSS_LOADER_EXPORT___;
