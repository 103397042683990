import clsx from "clsx"
import { useTranslation } from "react-i18next"
import styles from "./ProfileBlock.module.scss"
import { StarIcon } from "../../icons/StarIcon"
import CropModal from "../CropModal/CropModal"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { useAppDispatch } from "../../hooks"
import { IEmployee, IProfile } from "../../types/content"
import { ChatIcon } from "../../icons/ChatIcon"
import { useCreateChatMutation } from "../../redux/api/chat"
import { setMessagesModalFirstChat, setMessagesModalIsOpen } from "../../redux/slice/modals"
import BtnLoader from "../Assets/BtnLoader/BtnLoader"

interface Props {
  className?: string
  profileData: IProfile | IEmployee
  isProfilePage?: boolean
  setImg?: (file64: string) => void
  delPhotoFcn?: () => void
  notViewPhones?: boolean
  isWriteMes?: boolean
}

const ProfileBlock = ({
  className,
  profileData,
  isProfilePage,
  setImg,
  delPhotoFcn,
  notViewPhones,
  isWriteMes,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useAppDispatch()
  const [createChat, { isLoading: isLoadingCreateChat }] = useCreateChatMutation()

  const handleOpenChat = () => {
    createChat({ type: "users", id: profileData.id })
      .unwrap()
      .then((res) => {
        dispatch(setMessagesModalFirstChat(res))
        dispatch(setMessagesModalIsOpen(true))
      })
  }

  return (
    <div className={clsx(styles.ProfileBlock, className)}>
      <div className={styles["fio-container"]}>
        <span className={styles["fio"]}>{profileData.name}</span>
        {isProfilePage && "total_rating" in profileData && profileData.total_rating && (
          <span className={styles["rating"]}>
            <StarIcon />
            {profileData.total_rating}
          </span>
        )}
      </div>
      <CropModal
        addClass={styles["ProfileBlock__cropModal"]}
        submitModal={setImg}
        blockType={"profile"}
        delPhotoFcn={delPhotoFcn}
        photo={profileData.avatar_id}
      />
      <div className={styles["info-list"]}>
        {profileData.position && (
          <div className={styles["info-list__item"]}>
            <span className={styles["item__title"]}>{t("position")}</span>
            <span className={styles["item__info"]}>{profileData.position}</span>
          </div>
        )}
        {profileData.department_name && (
          <div className={styles["info-list__item"]}>
            <span className={styles["item__title"]}>{t("department")}</span>
            <span className={styles["item__info"]}>{profileData.department_name}</span>
          </div>
        )}

        {profileData.manager && (
          <div className={styles["info-list__item"]}>
            <span className={styles["item__title"]}>{t("leader")}</span>
            <div className={styles["item__info-container"]}>
              {profileData.manager.avatar_id && (
                <GetUserAvatar avatar={profileData.manager.avatar_id} name={profileData.manager.name} size={"sm"} />
              )}

              <span className={styles["item__info"]}>{profileData.manager.name}</span>
            </div>
          </div>
        )}
        {profileData.email && (
          <div className={styles["info-list__item"]}>
            <span className={styles["item__title"]}>Email</span>
            <span className={styles["item__info"]}>{profileData.email}</span>
          </div>
        )}
        {!notViewPhones && (
          <>
            {profileData.phone_number && (
              <div className={styles["info-list__item"]}>
                <span className={styles["item__title"]}>{t("mainPhone")}</span>
                <span className={styles["item__info"]}>{profileData.phone_number}</span>
              </div>
            )}
            {profileData.add_phone_number && (
              <div className={styles["info-list__item"]}>
                <span className={styles["item__title"]}>{t("additionalPhone")}</span>
                <span className={styles["item__info"]}>{profileData.add_phone_number}</span>
              </div>
            )}
          </>
        )}
      </div>
      {isProfilePage && <div className={styles["additional-information"]}>{t("txtChangeInfoSupport")}</div>}
      {isWriteMes && (
        <div className={styles["write-message__block"]}>
          <button
            disabled={isLoadingCreateChat}
            className={clsx(styles.btn, isLoadingCreateChat && styles["btn--is-loading"])}
            onClick={handleOpenChat}
          >
            <div className={styles["btn__icon-container"]}>
              {isLoadingCreateChat && <BtnLoader />}
              <ChatIcon />
            </div>

            {t("write")}
          </button>
        </div>
      )}
    </div>
  )
}

export default ProfileBlock
