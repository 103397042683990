import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { GetUserAvatar } from "../../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./Coordination.module.scss"
import { useTranslation } from "react-i18next"
import TextArea from "../../Assets/TextArea/TextArea"
import Button from "../../Assets/Button/Button"
import { DoubleCheckIcon } from "../../../icons/DoubleCheckIcon"
import { CrossIcon } from "../../../icons/CrossIcon"
import { useElapsedTime } from "../../../hooks/useElapsedTime"
import useWindowSize from "../../../hooks/useWindowSize"
import { ICoordination } from "../../../types/content"
import { useSetApproveCoordinationMutation } from "../../../redux/api/coordinations"

interface Props extends ICoordination {
  className?: string
  reFetch?: (noLoading?: boolean) => void
  setOpenedOrder: Dispatch<SetStateAction<any>>
  setIsOpenInfoModal: Dispatch<SetStateAction<boolean>>
  setChatID: Dispatch<SetStateAction<string>>
}

const Coordination: FC<Props> = ({
  className,
  id,
  sended_at,
  aproved_at,
  order,
  status,
  comment,
  reFetch,
  setOpenedOrder,
  setIsOpenInfoModal,
  setChatID,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [setApprove] = useSetApproveCoordinationMutation()
  const [commentTxt, setCommentTxt] = useState("")

  const { elapsed, date } = useElapsedTime(aproved_at ? aproved_at : sended_at ? sended_at : 0)
  const { isMobile } = useWindowSize()
  const currentLang = localStorage.getItem("i18nextLng") || "en"
  const [commentErr, setCommentErr] = useState(false)

  const createStatusWithDate = useMemo(() => {
    if (status === "in_progress") {
      return <>{elapsed}</>
    } else {
      const statusStr = status === "denied" ? "rejected" : "approved"
      return (
        <>
          {!isMobile && <>{t(statusStr)} - </>}
          {date}
        </>
      )
    }
  }, [elapsed, currentLang, isMobile])

  const statusLabel = (status: "denied" | "approved") => {
    const addedClass = status === "approved" ? "res" : "rej"

    return (
      <div className={clsx(styles["status-label"], styles[`status-label-${addedClass}`])}>
        {status === "approved" ? (
          <>
            <DoubleCheckIcon />
            {t("approved")}
          </>
        ) : (
          <>
            <CrossIcon />
            {t("rejected")}
          </>
        )}
      </div>
    )
  }

  const openChat = (dialogId: string) => {
    setIsOpenInfoModal(true)

    setOpenedOrder(order.id)
    setChatID(dialogId)
    setCommentTxt("")
  }

  const approveRejectCoordination = (type: boolean) => {
    if (!type && commentTxt.trim().length <= 0) {
      setCommentErr(true)
      return
    }

    setApprove({ id, isApproved: type, comment: commentTxt }).then(() => {
      if (reFetch) reFetch(true)
    })
  }

  useEffect(() => {
    if (commentTxt.trim().length > 0) setCommentErr(false)
  }, [commentTxt])

  return (
    <>
      <div
        className={clsx(styles.item, className)}
        onClick={(e) => {
          const el = e.target as HTMLElement
          const isElement = el.localName === "button" || el.localName === "textarea"
          if (!isMobile || isElement) return
          openChat(order.chat_dialog_id)
        }}
      >
        <div
          className={clsx(
            styles["item__top"],
            isMobile && status !== "in_progress" && styles["item__top__not-bot-padding"],
          )}
        >
          <div className={styles["title"]}>
            <h4
              className={clsx(
                styles["title__name"],
                isMobile && status === "in_progress" && styles["title__name__shorted"],
              )}
            >
              {order.name}
            </h4>
            <div className={styles["title__author"]}>
              <p className={styles["title__author-name"]}>{order.ordered_by?.name}</p>
              <GetUserAvatar
                avatar={order?.ordered_by?.avatar_id}
                name={order.ordered_by?.name}
                className={styles["title__author-avatar"]}
              />
            </div>
          </div>
          <div className={clsx(styles["body"], isMobile && status !== "in_progress" && styles["body__greyed"])}>
            <div className={styles["status-content"]}>
              <div
                className={clsx(
                  styles["status-time"],
                  isMobile && status === "in_progress" && styles["status-time__absolute"],
                )}
              >
                {isMobile && status !== "in_progress" && statusLabel(status)}
                {createStatusWithDate}
              </div>

              {status === "in_progress" ? (
                <div className={styles["text-container"]}>
                  <TextArea
                    autoSize
                    placeholder={"Комментарий к согласованию"}
                    minRows={1}
                    maxRows={2}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentTxt(e.target.value)}
                    value={commentTxt}
                    className={clsx(styles.textarea, commentErr && styles["is-error"])}
                  />
                </div>
              ) : (
                <div className={clsx(styles["text-container"], isMobile && styles["text-container__displaced"])}>
                  <p className={styles["status-comment"]}>{comment}</p>
                </div>
              )}
            </div>
            {!isMobile && status !== "in_progress" && statusLabel(status)}
          </div>
        </div>

        <div className={styles["item__bottom"]}>
          {!isMobile && (
            <Button
              txt={t("goToReq")}
              mode={"white"}
              className={styles.btn}
              onClick={() => {
                openChat(order.chat_dialog_id)
              }}
            />
          )}
          {status === "in_progress" && (
            <div className={styles["actions-btn-container"]}>
              <Button
                txt={t("reject")}
                mode={"warning"}
                className={styles.btn}
                onClick={() => approveRejectCoordination(false)}
              />
              <Button txt={t("approve")} className={styles.btn} onClick={() => approveRejectCoordination(true)} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Coordination
