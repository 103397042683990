// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlePrevItem_itemHeader__Ojn8e {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.ArticlePrevItem_info__qO-aT {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #7A808B;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
}

.ArticlePrevItem_date__5hOc\\+ {
  display: flex;
  align-items: center;
}
.ArticlePrevItem_date__5hOc\\+ span {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--text-color);
  flex-shrink: 0;
  margin: 0 5px;
}

.ArticlePrevItem_txt__E8Mro {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: var(--transition);
  line-height: 120%;
}
@media (hover: hover) {
  .ArticlePrevItem_txt__E8Mro:hover {
    text-decoration: underline;
  }
}

.ArticlePrevItem_description__1-jF5 {
  font-size: 12px;
  line-height: 14px;
  color: #7A808B;
  margin-top: 6px;
}

.ArticlePrevItem_tags__smZ7h {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlePrevItem/ArticlePrevItem.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;AAHF;AAKE;EACE,qBAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,mCAAA;EACA,cAAA;EACA,aAAA;AAHJ;;AAOA;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;EACA,6BAAA;EACA,iBAAA;AAJF;AAME;EACE;IACE,0BAAA;EAJJ;AACF;;AAQA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AALF;;AAQA;EACE,aAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;AALF","sourcesContent":[".item {\r\n  //padding: 12px 16px;\r\n}\r\n\r\n.itemHeader {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 6px;\r\n}\r\n\r\n.info {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n  color: #7A808B;\r\n  font-size: 12px;\r\n  line-height: 14px;\r\n  margin-left: 4px;\r\n}\r\n\r\n.date {\r\n  display: flex;\r\n  align-items: center;\r\n\r\n  span {\r\n    display: inline-block;\r\n    width: 3px;\r\n    height: 3px;\r\n    border-radius: 100%;\r\n    background-color: var(--text-color);\r\n    flex-shrink: 0;\r\n    margin: 0 5px;\r\n  }\r\n}\r\n\r\n.txt {\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 4;\r\n  -webkit-box-orient: vertical;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  transition: var(--transition);\r\n  line-height: 120%;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      text-decoration: underline;\r\n    }\r\n  }\r\n}\r\n\r\n.description {\r\n  font-size: 12px;\r\n  line-height: 14px;\r\n  color: #7A808B;\r\n  margin-top: 6px;\r\n}\r\n\r\n.tags {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  grid-gap: 4px;\r\n  margin-top: 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemHeader": `ArticlePrevItem_itemHeader__Ojn8e`,
	"info": `ArticlePrevItem_info__qO-aT`,
	"date": `ArticlePrevItem_date__5hOc+`,
	"txt": `ArticlePrevItem_txt__E8Mro`,
	"description": `ArticlePrevItem_description__1-jF5`,
	"tags": `ArticlePrevItem_tags__smZ7h`
};
export default ___CSS_LOADER_EXPORT___;
