import { api } from "./index"
import { BaseResponseType, IOrderReview, IOrderWithoutReview, IReview } from "../../types/content"

export const reviewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Сортировка по reviewed_at
    getReviews: builder.query<
      { bIsEnd: boolean; iCount: number; reviews: IReview[] },
      { limit?: number; offset?: number } | void
    >({
      query: (data) => {
        let queryString = ""

        if (data) {
          for (const key in data) {
            if (!queryString) queryString = "?"
            if (queryString && queryString !== "?") queryString = queryString + "&"
            // @ts-ignore
            queryString = queryString + `${key}=${data[key]}`
          }
        }

        return {
          url: `public/service-orders/all-reviews${queryString}`,
          method: "GET",
        }
      },
    }),
    getReviewsByOrderID: builder.query<IOrderReview[], string>({
      query: (id) => ({
        url: `public/service-orders/${id}/reviews`,
        method: "GET",
      }),
    }),
    // получить краткую инфу по заявкам и исполнителям. Заявки, у которых нет отзывов. Сортировка по created_at
    getOrdersWithoutReviews: builder.query<
      { bIsEnd: boolean; iCount: number; orders: IOrderWithoutReview[] },
      { limit?: number; offset?: number; search?: string } | void
    >({
      query: (data) => {
        let queryString = ""

        if (data) {
          for (const key in data) {
            if (!queryString) queryString = "?"
            if (queryString && queryString !== "?") queryString = queryString + "&"
            // @ts-ignore
            queryString = queryString + `${key}=${data[key]}`
          }
        }

        return {
          url: `public/service-orders/orders-without-reviews${queryString}`,
          method: "GET",
        }
      },
      transformResponse: (res: BaseResponseType<{ bIsEnd: boolean; iCount: number; orders: IOrderWithoutReview[] }>) =>
        res.data,
    }),
    sendReview: builder.query<IReview, { id: string; txt: string; rating: number }>({
      query: ({ id, txt, rating }) => ({
        url: `public/service-orders/${id}/reviews`,
        method: "POST",
        body: {
          text: txt,
          rating: rating,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useLazyGetReviewsByOrderIDQuery,
  useLazyGetOrdersWithoutReviewsQuery,
  useLazyGetReviewsQuery,
  useLazySendReviewQuery,
} = reviewApi
