import React, { CSSProperties } from "react"
import { Link, useParams } from "react-router-dom"
import { prefixPath } from "../utils/helpers"

interface Props {
  to: string
  children?: any
  className?: string
  style?: CSSProperties
  onClick?: any
  relative?: any
  isTarget?: boolean
}

const LocalizedLink: React.FC<Props> = ({
  children,
  to,
  className,
  style,
  relative,
  onClick = () => null,
  isTarget,
}) => {
  const params = useParams()

  return (
    <Link
      className={className}
      to={params?.locale ? prefixPath(to, params?.locale) : `${to}`}
      relative={relative && relative}
      style={style ?? {}}
      onClick={onClick}
      target={isTarget ? "_blank" : undefined}
    >
      {children}
    </Link>
  )
}

export default LocalizedLink
