export const TrashIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66602 10L6.66602 8" stroke="#F2200D" strokeWidth="1.33333" strokeLinecap="round" />
      <path d="M9.33398 10L9.33398 8" stroke="#F2200D" strokeWidth="1.33333" strokeLinecap="round" />
      <path
        d="M2 4.66602H14V4.66602C13.3787 4.66602 13.0681 4.66602 12.8231 4.76751C12.4964 4.90283 12.2368 5.1624 12.1015 5.4891C12 5.73413 12 6.04476 12 6.66602V10.666C12 11.9231 12 12.5516 11.6095 12.9422C11.219 13.3327 10.5904 13.3327 9.33333 13.3327H6.66667C5.40959 13.3327 4.78105 13.3327 4.39052 12.9422C4 12.5516 4 11.9231 4 10.666V6.66602C4 6.04476 4 5.73413 3.89851 5.4891C3.76318 5.1624 3.50362 4.90283 3.17691 4.76751C2.93188 4.66602 2.62126 4.66602 2 4.66602V4.66602Z"
        stroke="#F2200D"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M6.71275 2.24706C6.78872 2.17618 6.95611 2.11355 7.18897 2.06888C7.42183 2.02421 7.70714 2 8.00065 2C8.29416 2 8.57947 2.02421 8.81233 2.06888C9.04519 2.11355 9.21259 2.17618 9.28855 2.24706"
        stroke="#F2200D"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    </svg>
  )
}
