import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./CropModal.module.scss"
import "cropperjs/dist/cropper.css"
import CropModalPopup from "./CropModalPopup"
import { TrashIcon } from "../../icons/TrashIcon"
import { PlusIcon } from "../../icons/PlusIcon"
import clsx from "clsx"
import useWindowSize from "../../hooks/useWindowSize"
import { getApiUrl } from "../../utils/getApiUrl"

interface Props {
  addClass?: string
  photo?: string | null
  submitModal?: any
  blockType?: string
  delPhotoFcn?: () => void
}
const CropModal: React.FC<Props> = ({ addClass, photo, submitModal, blockType, delPhotoFcn }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const inputRef = useRef<any>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const { isMobile } = useWindowSize()
  const [image, setImage] = useState("")
  const onChange = (e: any) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader: any = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(files[0])
    setModalOpen(true)
  }

  useEffect(() => {
    if (!modalOpen) {
      inputRef.current.value = ""
    }
  }, [modalOpen])

  let photoSrc
  switch (blockType) {
    case "profile":
      photoSrc = getApiUrl() + "/web/user/avatar/" + photo
      break

    default:
      break
  }

  const addPhoto = () => {
    inputRef.current.click()
  }

  return (
    <>
      <div className={clsx(styles.cropModal__photo, addClass)}>
        <div
          className={clsx(styles.cropModal__photo__container, "photo__container", {
            [styles["no-filling"]]: photo,
          })}
        >
          {photo ? (
            <img className={`${styles["photo-img"]}`} src={photoSrc} alt="" />
          ) : (
            <svg viewBox="0 0 64 64">
              <path
                d="M42.3034 43.2627C41.6956 41.5616 40.3565 40.0585 38.4936 38.9864C36.6307 37.9144 34.3483 37.3333 32.0002 37.3333C29.6521 37.3333 27.3696 37.9144 25.5067 38.9864C23.6438 40.0585 22.3047 41.5616 21.697 43.2627"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <circle cx="31.9998" cy="26.6666" r="5.33333" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
            </svg>
          )}
        </div>

        <input
          type="file"
          className={`${styles["cropModal__photo__input"]}`}
          accept="image/*"
          onChange={onChange}
          ref={inputRef}
        />
        {photo && delPhotoFcn && (
          <button
            type="button"
            className={clsx(styles["cropModal__photo__btn"], "loaded", styles["remove-btn"])}
            onClick={delPhotoFcn}
          >
            <TrashIcon /> {!isMobile && t("removePhoto")}
          </button>
        )}
        {!photo && submitModal && (
          <button
            type="button"
            className={clsx(styles["cropModal__photo__btn"], "loaded", styles["add-btn"])}
            onClick={addPhoto}
          >
            <PlusIcon /> {!isMobile && t("addPhoto")}
          </button>
        )}
      </div>
      {modalOpen && (
        <CropModalPopup modalOpen={modalOpen} setModalOpen={setModalOpen} submitModal={submitModal} image={image} />
      )}
    </>
  )
}

export default CropModal
