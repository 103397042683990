import clsx from "clsx"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"
import { IOrderWithoutReview, IReview } from "../../types/content"
import { LInkIcon } from "../../icons/LinkIcon"
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react"
import useWindowSize from "../../hooks/useWindowSize"
import UserAvatars from "../Assets/UserAvatars/UserAvatars"
import styles from "./Review.module.scss"
import IconBtn from "../Assets/IconBtn/IconBtn"
import Button from "../Assets/Button/Button"
import { useTranslation } from "react-i18next"
import TextArea from "../Assets/TextArea/TextArea"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { useLazySendReviewQuery } from "../../redux/api/review"
import RatingModal from "../Modals/RatingModal/RatingModal"

interface Props extends IReview {
  setModalInfo: Dispatch<SetStateAction<{ chat_dialog_id: string; order_id: string }>>
  setReviews: Dispatch<
    SetStateAction<{
      orders: IOrderWithoutReview[]
      reviews: IReview[]
      isOrdersEnd: boolean
      isReviewsEnd: boolean
    }>
  >
  className?: string
}

const Review = ({
  order_id,
  order_name,
  chat_dialog_id,
  text,
  reviewed_at,
  assigneeUsers,
  rating,
  setModalInfo,
  setReviews,
  className,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const [sendReview, { isLoading }] = useLazySendReviewQuery()

  const [isEdit, setEdit] = useState<boolean>(false)
  const [valueTxt, setValueTxt] = useState<string | null>(text)
  const [ratingValue, setRatingValue] = useState<number>(rating)
  const [openRatingModal, setOpenRatingModal] = useState<boolean>(false)

  const openModal = () => {
    setModalInfo({ chat_dialog_id: chat_dialog_id, order_id: order_id })
  }

  const handleCancel = () => {
    setRatingValue(rating)
    setValueTxt(text)
    setEdit(false)
  }

  const handleSubmit = () => {
    sendReview({ id: order_id, txt: valueTxt || "", rating: ratingValue })
      .then((res) => {
        if (!res?.data) return
        setReviews((prev) => {
          if (!res.data) return prev
          const index = prev.reviews.findIndex((i) => i.order_id === order_id)
          const cloneReviews = [...prev.reviews]
          if ((index !== 0 && !index) || !cloneReviews[index]) return prev
          cloneReviews[index] = {
            ...cloneReviews[index],
            text: res.data.text,
            rating: res.data.rating,
            reviewed_at: res.data.reviewed_at,
          }
          return {
            ...prev,
            orders: [...prev.orders.filter((order) => order.id !== order_id)],
            reviews: cloneReviews,
          }
        })
        setEdit(false)
      })
      .catch((err) => console.error(err.message))
  }

  return (
    <>
      <div
        className={clsx(styles.item, className)}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          if (isDesktop || (e.target as HTMLDivElement).closest("button")) return
          openModal()
        }}
      >
        <div className={styles.body}>
          <h4 className={styles.title}>
            <span>{order_name}</span>
            {!isDesktop && (
              <StarsRating
                className={styles.stars}
                rating={ratingValue}
                size={"sm"}
                name={"review-rate-" + order_id}
                disabled
              />
            )}
            {isDesktop && !isEdit && (
              <button type={"button"} className={styles.btnLink} onClick={openModal}>
                <LInkIcon />
              </button>
            )}
            {isDesktop && !isEdit && (
              <IconBtn
                className={styles.editBtn}
                icon={"edit"}
                size={"esm"}
                mode={"gray2"}
                borderSize={"circle"}
                onClick={() => {
                  setEdit(true)
                }}
              />
            )}
            {!isDesktop || isEdit ? (
              <p className={clsx(styles.time, styles.timeInHeader)}>{moment(reviewed_at * 1000).format("kk:mm")}</p>
            ) : null}
          </h4>

          {!isEdit && text && <p className={styles.txt}>{text}</p>}

          <div className={styles.main}>
            <div className={clsx(styles.assignees, !isDesktop && styles["assignees--more"])}>
              {isDesktop && isEdit && (
                <>
                  {assigneeUsers?.map((assignee) => {
                    return (
                      <div key={assignee.id} className={styles.assignee}>
                        <GetUserAvatar
                          avatar={assignee?.avatar_id}
                          name={assignee?.name}
                          className={styles.avatar}
                          size={"sm"}
                        />
                        <div>
                          <p className={styles.name}>{assignee.name}</p>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
              {!isEdit && (
                <>
                  <UserAvatars items={assigneeUsers} className={styles.avatars} />
                  <div>
                    {assigneeUsers?.map((assignee, index) => {
                      if (index > 4) return
                      const suf = index + 1 !== assigneeUsers.length ? ", " : ""
                      const more = index === 4 && assigneeUsers.length > 5 ? `еще ${assigneeUsers.length - 5}..` : ""
                      return (
                        <span key={assignee.id} className={styles.name}>
                          {assignee.name}
                          {suf}
                          <span>{more}</span>
                        </span>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
            {isDesktop && (
              <>
                <StarsRating
                  className={clsx(styles.stars, isEdit && styles["stars--is-edit"])}
                  rating={ratingValue}
                  size={!isEdit ? "sm" : undefined}
                  name={"review-rate-" + order_id}
                  disabled={!isEdit}
                  onChange={(val) => {
                    setRatingValue(val)
                  }}
                />
                {!isEdit && <p className={styles.time}>{moment(reviewed_at * 1000).format("kk:mm")}</p>}
              </>
            )}
          </div>

          {isEdit && (
            <div className={styles.bot}>
              <label className={styles.label}>{t("leaveComment")}</label>
              <TextArea
                autoSize
                placeholder={t("describeHowWent")}
                minRows={1}
                maxRows={5}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValueTxt(e.target.value)}
                value={valueTxt || ""}
                className={styles.textarea}
              />
            </div>
          )}
        </div>

        {isDesktop && isEdit && (
          <footer className={styles.footer}>
            <Button
              className={styles.btnCancel}
              txt={t("cancel")}
              mode={"gray"}
              onClick={handleCancel}
              disabled={isLoading}
            />
            <Button txt={t("goToReq")} mode={"white"} onClick={openModal} disabled={isLoading} />
            <Button txt={t("giveFeedback")} onClick={handleSubmit} disabled={isLoading} isLoading={isLoading} />
          </footer>
        )}
        {!isDesktop && (
          <footer className={styles.footer}>
            <Button txt={t("edit")} mode={"gray"} onClick={() => setOpenRatingModal(true)} />
          </footer>
        )}
      </div>

      {openRatingModal && order_id && (
        <RatingModal
          open={openRatingModal}
          setOpen={setOpenRatingModal}
          orderId={order_id}
          setReviews={setReviews}
          initialTxt={text}
          initialRating={rating}
        />
      )}
    </>
  )
}

export default Review
