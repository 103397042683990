import { useEffect } from "react"
import clsx from "clsx"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import styles from "./Profile.module.scss"
import { useLazyGetProfileQuery, useSetProfileAvatarMutation } from "../../redux/api/auth"
import { useAppDispatch } from "../../hooks"
import { setProfileAvatar } from "../../redux/slice/auth"
import ProfileBlock from "../ProfileBlock/ProfileBlock"

interface Props {
  className?: string
}

const Profile = ({ className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useAppDispatch()
  const [getProfileData, { isLoading: isLoadingProfile, data: profileData }] = useLazyGetProfileQuery()
  const [setAvatar, { isLoading: isLoadingAvatar }] = useSetProfileAvatarMutation()

  useEffect(() => {
    getProfileData()
  }, [])

  const setImg = async (file64: string) => {
    if (isLoadingProfile) return
    await setAvatar({ avatar: file64 })
    getProfileData()
      .unwrap()
      .then((res) => {
        dispatch(setProfileAvatar(res.data.avatar_id))
      })
  }

  const delPhotoFcn = async () => {
    if (isLoadingAvatar) return
    await setAvatar({ avatar: null })
    getProfileData()
      .unwrap()
      .then((res) => {
        dispatch(setProfileAvatar(res.data.avatar_id))
      })
  }

  return (
    <div className={styles.Profile}>
      <TitleBack title={t("profile")} className={styles["title-back"]} />
      <div className={clsx(styles["profile-container"], { [styles["is-loading"]]: isLoadingProfile })}>
        {!isLoadingProfile && profileData ? (
          <ProfileBlock profileData={profileData?.data} isProfilePage setImg={setImg} delPhotoFcn={delPhotoFcn} />
        ) : (
          <div className={styles["loader-container"]}>
            <div className={styles["fio-container"]}>
              <div className={clsx("skeletonBlock", styles.loadingBlock)} />
            </div>

            <div className={styles["Profile__cropModal"]}>
              <div className={clsx("skeletonBlock", styles.loadingBlock)} />
            </div>
            <div className={styles["info-list"]}>
              {[...Array(3)].map((_item, index) => (
                <div key={index} className={clsx("skeletonBlock", styles.loadingBlock)} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile
