import { IImportantEvent } from "../../../types/content"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { Dispatch, SetStateAction, useRef, useState } from "react"
import { getApiUrl } from "../../../utils/getApiUrl"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperOptions } from "swiper/types/swiper-options"
import { Navigation, Pagination, Autoplay } from "swiper/modules"
import clsx from "clsx"
import type { Swiper as SwiperClass } from "swiper/types"
import { SwiperRef } from "swiper/swiper-react"
import { useLazyMarkReadImportantEventsQuery } from "../../../redux/api/important-events"
import SliderNav from "../../Assets/SliderNav/SliderNav"
import styles from "./EventsModal.module.scss"

const swiperParams: SwiperOptions = {
  slidesPerView: 1,
  modules: [Navigation, Pagination, Autoplay],
  pagination: {
    clickable: true,
    el: ".events-pag",
    renderBullet: function (_index: number, className: string) {
      return `<span class="${clsx(className, styles.pagItem)}"><span></span></span>`
    },
  },
  autoplay: {
    delay: 4_000,
    pauseOnMouseEnter: true,
  },
}

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  events: IImportantEvent[]
  setEvents: Dispatch<SetStateAction<IImportantEvent[]>>
  startSlide?: number
}

const EventsModal = ({ open, setOpen, events, setEvents, startSlide }: Props) => {
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const slider = useRef<SwiperRef>(null)

  const [markEvent] = useLazyMarkReadImportantEventsQuery()

  const [isForceView, setForceView] = useState<boolean>(false)

  const onAutoplayTimeLeft = (swiper: SwiperClass, _time: number, progress: number) => {
    if (!paginationRef?.current) return
    const bullet = paginationRef.current.querySelector(".swiper-pagination-bullet-active span") as HTMLDivElement
    const percentNumber = 100 - progress * 100
    if (bullet) bullet.style.width = percentNumber + "%"
    const currentEvent = events[swiper.realIndex]
    if (events?.length === 1 && percentNumber > 95 && currentEvent.force_view && currentEvent.isViewed && isForceView) {
      // если событие всего одно и принудительное, то после 95% его показа, разблокируем закрытие слайдера
      setForceView(false)
    }
  }

  const setPaginationWidth = (currentNumber: number) => {
    if (!paginationRef?.current) return
    setRead(events[currentNumber].id)
    const bullets = paginationRef.current.querySelectorAll(".swiper-pagination-bullet") as NodeListOf<HTMLDivElement>
    bullets?.forEach((bull, index) => {
      const el = bull.querySelector("span") as HTMLDivElement
      if (index > currentNumber) {
        el.style.width = "0"
      } else if (index < currentNumber) {
        el.style.width = "100%"
      }
    })
  }

  const setRead = (id: string) => {
    const event = events.find((ev) => ev.id === id)
    const index = events.findIndex((i) => i.id === id)
    if (index < 0 || event?.isViewed) return
    try {
      markEvent([id]).then(() => {
        setEvents((prev) => {
          const clone = [...prev]
          clone[index] = { ...clone[index], isViewed: true }
          return clone
        })
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={clsx(styles.modal, isForceView && styles["modal--is-force-view"])}
      isCloseBtn={!isForceView}
    >
      <div ref={paginationRef} className={clsx(styles.pag, "events-pag")} />
      <SliderNav className={clsx(styles.nav)} mode={"black"} size={"lg"} prevRef={prevRef} nextRef={nextRef} />
      <Swiper
        {...swiperParams}
        initialSlide={startSlide}
        ref={slider}
        className={styles.slider}
        onBeforeInit={(swiper: SwiperClass) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
          const currentEvent = events[swiper.realIndex]
          setForceView(currentEvent.force_view && !currentEvent.isViewed)
        }}
        onAfterInit={() => {
          if (startSlide !== 0 && !startSlide) return
          setPaginationWidth(startSlide)
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={(swiper: SwiperClass) => {
          const currentEvent = events[swiper.realIndex]
          setForceView(currentEvent.force_view && !currentEvent.isViewed)
          setPaginationWidth(swiper.realIndex)
        }}
      >
        {events?.map((event) => (
          <SwiperSlide
            key={event.id}
            style={{
              backgroundImage: `url(${
                event.image_id ? getApiUrl() + "/public/photo/" + event.image_id : "/img/events-template.jpg"
              })`,
            }}
            className={styles.slide}
          >
            <div className={styles.content}>
              <h2 className={styles.title}>{event.name}</h2>
              {event?.description && <p className={styles.txt}>{event.description}</p>}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </ModalPortal>
  )
}

export default EventsModal
